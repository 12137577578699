<template>
  <div>
    <p class="h4 pt-3 pb-4">积分日志</p>
    <el-table :data="tableData" style="width:100%">
      <el-table-column prop="username" :formatter="(row) => getName(row)" label="用户名"></el-table-column>
      <el-table-column prop="point"  label="积分"></el-table-column>
      <el-table-column label="projectid" prop="projectid"></el-table-column>
      <el-table-column label="备注" :formatter="(row) => row.msg || '--'"></el-table-column>
      <el-table-column label="创建时间" :formatter="(row) => getTime(row.created)"></el-table-column>
    </el-table>
    <el-pagination
      class="mt-3"
      background
      layout="prev, pager, next"
      :current-page.sync="pages.page"
      :total="cnt"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { rewardLogs } from '@/api/points'
export default {
  name: 'log',
  components: {
  },
  data () {
    return {
      tableData: [],
      pages: {
        page: 1,
        pagesize: 10
      },
      cnt: 0
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    getName (row) {
      if (row.ltype) {
        return '工会:' + row.teamid
      } else {
        return row.username
      }
    },
    getData () {
      rewardLogs(this.pages).then(
        res => {
          const { cnt, logs } = res.data
          this.tableData = logs
          this.cnt = cnt
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    },
    handleCurrentChange (val) {
      this.pages.page = val
      this.getData()
    },
    getTime (time) {
      const curTime = new Date(time * 1000)
      const year = curTime.getFullYear()
      const month = curTime.getMonth() + 1
      const day = curTime.getDate()
      const hour = curTime.getHours() + 1
      const minute = curTime.getMinutes()
      const second = curTime.getSeconds()
      return `${year}年${month}月${day}日 ${hour > 9 ? hour : '0' + hour}:${minute > 9 ? minute : '0' + minute}:${second > 9 ? second : '0' + second}`
    }
  }
}
</script>

<style>
</style>
